import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BotMessageComponent from "../components/BotMessage";
import LogoComponent from "../components/LogoComponent";
import { TRANSITION_TIME_IN } from "../theme/gaimcontrol";
import { sprintf } from "sprintf-js";
import AnswerSelectorComponent from "../components/AnswerSelector";
import { useNavigate } from "react-router-dom";
import ProgressIndicatorComponent from "../components/ProgressIndicator";
import UserContext from "../context/UserContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "axios";

const AnnaText = "It has been a pleasure to meet you%s, and thank you to your honest answers. " +
  "Before be go further, can you tell me how did it go for you? Also, leave your email so we can stay " +
  "connected on your journey."

function FollowUpComponent( ) {
  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(100);
  const [value, setValue] = useState<number>(50);
  const [emailError, setEmailError] = useState<boolean>(false);
  const { name, email, score, updateUser } = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  const isSubmitEnabled = () : boolean => {
    return (max < 4) && !emailError && (email !== undefined) && (email.length > 0)
  }

  const onValueChange = ( event: Event, value: number | number[] ) => {
    if (max > 3) {
      setMin(0);
      setMax(3);
      setValue(1);
    } else {
      setValue(value as number)
    }
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      setEmailError(false);
      updateUser && updateUser(name, score, event.target.value);
    } else {
      setEmailError(true);
    }
  };

  const onSubmit = () => {
    if (!isSubmitEnabled()) {
      return;
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    executeRecaptcha('submit').then((token) => {
      axios.post(process.env.REACT_APP_CHAT_SERVER_URL + "/users", {
        email: email
      }, {
        headers: {
        "Authorization": process.env.REACT_APP_API_TOKEN,
        "Verification-Code": token
        }
      })
    }).catch((error) => {
      console.log('Error: ', error);
    }).then(() => {
      navigate("/result")
    });
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent />

      <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <BotMessageComponent timeout={TRANSITION_TIME_IN} text={sprintf(AnnaText, name ? ", " + name : "")}/>
        <AnswerSelectorComponent onChange={onValueChange} timeout={TRANSITION_TIME_IN} min={min} max={max} value={value} options={["It was awful", "Meh", "Ok", "This was awesome!"]} />
        <TextField
          label="Email"
          type="email"
          sx={{flexGrow: 1}}
          inputProps={{style: {textAlign: "center"}}}
          variant="standard"
          placeholder="name@example.com"
          onChange={onEmailChange}
          onKeyDown={(event) => event.key === 'Enter' && onSubmit()}
          error={emailError}
          helperText={emailError ? "Please enter a valid email" : " "}
        />
        <Button variant="contained" onClick={onSubmit} disabled={!isSubmitEnabled()} sx={{paddingX: 6, marginY: 6}}>Next</Button>
      </Box>
      <ProgressIndicatorComponent position={9}/>
    </Stack>
  )
}

export default FollowUpComponent;
